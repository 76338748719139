import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    button: "",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getOrderDetail(_ctx.purchaseOrder.orderId)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => [
          _createElementVNode("h3", null, _toDisplayString(_ctx.purchaseOrder.externalOrderId), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.purchaseOrder.orderName ? _ctx.purchaseOrder.orderName : _ctx.purchaseOrder.orderId), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_label, {
        class: "ion-text-end",
        slot: "end"
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.purchaseOrder.estimatedDeliveryDate ? _ctx.$filters.formatUtcDate(_ctx.purchaseOrder.estimatedDeliveryDate, 'YYYY-MM-DDTHH:mm:ssZ') : " - "), 1),
          _createVNode(_component_ion_badge, {
            color: _ctx.orderStatusColor[_ctx.purchaseOrder.orderStatusId]
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.purchaseOrder.orderStatusDesc), 1)
            ]),
            _: 1
          }, 8, ["color"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}