import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "overline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    button: "",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.viewShipment()))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("p", { class: "overline" }, _toDisplayString(_ctx.shipment.externalOrderName ? _ctx.shipment.externalOrderName : _ctx.shipment.externalOrderId), 513), [
            [_vShow, _ctx.shipment.externalOrderId || _ctx.shipment.externalOrderName]
          ]),
          _createElementVNode("h2", null, _toDisplayString(_ctx.shipment.externalId ? _ctx.shipment.externalId : _ctx.shipment.shipmentId), 1),
          (_ctx.shipment.shipmentItemCount)
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.shipment.shipmentItemCount) + " " + _toDisplayString((_ctx.shipment.shipmentItemCount > 1 ? 'Items' : 'Item')), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_label, {
        class: "ion-text-end",
        slot: "end"
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.shipment.estimatedArrivalDate ? (_ctx.$filters.formatDate(_ctx.shipment.estimatedArrivalDate)) : _ctx.shipment.statusDesc), 1),
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.shipment.trackingIdNumber), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}