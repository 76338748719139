import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_chip = _resolveComponent("ion-chip")!

  return (_openBlock(), _createBlock(_component_ion_chip, { outline: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_select, {
        "aria-label": "Facility Location",
        interface: "popover",
        placeholder: _ctx.translate('facility location'),
        value: _ctx.item.locationSeqId,
        onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setFacilityLocation($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: _ctx.locationOutline
          }, null, 8, ["icon"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.getFacilityLocationsByFacilityId(_ctx.facilityId) ? _ctx.getFacilityLocationsByFacilityId(_ctx.facilityId) : []), (facilityLocation) => {
            return (_openBlock(), _createBlock(_component_ion_select_option, {
              key: facilityLocation.locationSeqId,
              value: facilityLocation.locationSeqId
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(facilityLocation.locationPath ? facilityLocation.locationPath : facilityLocation.locationSeqId), 1)
              ]),
              _: 2
            }, 1032, ["value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["placeholder", "value"])
    ]),
    _: 1
  }))
}